html,
body {
    margin: 0 auto;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
    height: 100%;
    max-width: 1200px;
}

/* Header 영역 */
header {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    background: white;
    z-index: 2;
    max-width: 1200px;
}

/* Main 영역 */
main {
    width: 100%;
    padding-top: 60px;
    height: calc(100vh - 160px);
    padding-bottom: 100px;
    overflow: auto;
    z-index: 1;
}

.valid_order {
	padding: 0 20px;
    box-sizing: border-box;
}
.card_container {
	padding: 0 20px;
    box-sizing: border-box;
}

.title {
    width: 100%;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    height: 40px;
    padding-bottom: 20px;
    line-height: 40px;
}

.saved_info {
    padding-bottom: 10px;
    padding-top: 5px;
    font-size: 15px;
    color: #ff6700;
    padding-left: 20px;
    padding-right: 20px;
}

.place_info {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    line-height: 20px;
}

.place_info_text {
    font-size: 15px;
    display: inline-block;
}

.receiver_address {
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 10px;
    height: 20px;
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    line-height: 30px;
}

.place_info_text_bold {
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.delivery_location_container {
    width: calc(100%-40px);
    padding-left: 20px;
    padding-right: 20px;
}

label {
    height: 44px;
    display: flex;
    margin: auto 0;
    align-items: center;
}

.label_font {
    font-size: 15px;
    height: 44px;
    font-weight: normal;
    padding-left: 3px;
    align-items: center;
    line-height: 44px;
}

.entrance_container {
    width: 100%;
	overflow: auto;
	height: auto;
	margin-bottom: 10px;
}

.entrance_title {
    width: calc(100%-40px);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    height: 44px;
    line-height: 40px;
    font-size: 15px;
    font-weight: 600;
}

.entrance_title_line {
    width: 100%;
    height: 0.5px;
    background-color: #C7C7CC;
    margin-bottom: 10px;
}

.notice_container {
    width: calc(100%-40px);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.notice_title {
    height: 30px;
    display: flex;
}

.notice_title_font {
    height: 30px;
    line-height: 30px;
    margin-left: 3px;
    font-size: 15px;
    color: #ff6700;
    font-weight: 600;
}

.notice_desc_font {
    font-size: 15px;
    color: #ff6700;
    padding-top: 5px;
}

.notice_title img {
    width: 22px;
    height: 22px;
}

.notice_title div {
    margin-left: 5px;
}

/* Footer 영역 */
footer {
    width: calc(100% - 30px);
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    bottom: 0px;
    position: fixed;
    padding-bottom: 20px;
    background: white;
    max-width: 1200px;
    z-index: 2;
}

.button {
    width: 100%;
    height: 50px;
    background-color: #1A1A1A;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    border-radius: 10px;
    border: 0;
}

.button:enabled {
    background-color: #1A1A1A;
    color: #FFFFFF;
}

.button:disabled {
    background-color: #F6F6F6;
    color: #C7C7C7;
}

/* 기타 영역 */
input[type=radio] {
    border: 1px solid #C7C7CC;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    display: none;
}

input[type=radio]+label>img {
    height: 22px;
    width: 22px;
    display: inline-block;
    content: url("https://cdn.0br1.io/img/icons/circle_72x72.png");
    margin-right: 5px;
}

input[type=radio]:checked+label>img {
    height: 22px;
    width: 22px;
    display: inline-block;
    content: url("https://cdn.0br1.io/img/icons/circle_checked_72x72.png");
    margin-right: 5px;
}

input[type=radio]:hover+label>img {
    transform: scale(1.1);
}

.input_etc_place {
    width: 100%;
    height: 44px;
    display: block;
    font-size: 15px;
    box-sizing: border-box;
    border-width: 0 0 0.5px;
    border-color: #E5E5EA;
}

.input_etc_place:focus {
    width: 100%;
    height: 44px;
    display: block;
    font-size: 15px;
    box-sizing: border-box;
    outline: none;
    border-width: 0 0 0.5px;
    border-color: #ff6700;
}

.input_box {
    width: calc(80% - 10px);
    height: 80px;
    display: block;
    font-size: 30px;
    box-sizing: border-box;
    padding-left: 6px;
    padding-right: 6px;
    border: solid 0.5px #E5E5EA;
    border-radius: 5px;
    color: black;
	margin-right: 10px;
	float:left;
}

.search_order_button {
	width: 20%;
	height: 80px;
}

.input_box::placeholder {
    color: #8E8E93;
    font-weight: normal;
}

.input_box:focus-visible {
    outline: none;
    border: solid 0.5px #ff6700;
}

.spinner {
	height: 80px !important;
	width: 80px !important;
}
.spinner_container {
    text-align: center;
	width: 20%;
	float: left;
}

.MuiCircularProgress-colorPrimary {
    color: #FF6700 !important;
}

.title {
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
    padding: 10px;
}
.order_number {
	width: 100%;
	text-align: center;
	padding: 20px;
    font-size: 25pt;
	box-sizing: border-box;
	background-color: #ffc7a1;
}
.rack_number {
    width: 100%;
    text-align: center;
    padding: 20px;
    font-size: 25pt;
    box-sizing: border-box;
}
.sender_info {
	width: 100%;
	text-align: center;
    font-size: 25pt;
	font-weight: bold;
	padding: 20px;
	box-sizing: border-box;
}
.address {
	width: 100%;
	text-align: center;
    font-size: 25pt;
	font-weight: bold;
	padding: 20px;
	box-sizing: border-box;
}